import React, { SVGProps } from 'react';

export const PhoneFilledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg role="none" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g />
    <g>
      <path
        d="M8.1,7.4L3.4,2.6l1.8-1.8l3.7,3.7c0.6,0.6,0.6,1.5,0,2.1L8.1,7.4z M19.5,15.1c-0.6-0.6-1.5-0.6-2.1,0
                l-0.7,0.7l4.8,4.8l1.8-1.8L19.5,15.1z M15.9,16.6l-1.1,1.1c-0.4,0.4-1,0.4-1.4,0l-7.1-7.1c-0.4-0.4-0.4-1,0-1.4l1.1-1.1L2.7,3.3
                C0.1,6.3,0.2,10.7,3,13.6l7.4,7.4c2.8,2.8,7.3,2.9,10.2,0.3L15.9,16.6z"
      />
    </g>
  </svg>
);
