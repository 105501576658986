import React from 'react';

export const StreamingIcon = () => {
  return (
    <svg role="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g />
      <g>
        <g>
          <path d="M20.23,3.87H1.13v4.34c.21-.02,.43-.03,.64-.03,.72,0,1.41,.09,2.09,.26v-1.84h15.47c.5,0,.91,.41,.91,.91v7.28H10.25c.22,.88,.3,1.8,.24,2.73h12.48V6.6c0-1.51-1.22-2.73-2.73-2.73Z" />
          <path d="M14.77,19.33h-5.46c-1.51,0-2.73,1.22-2.73,2.73h10.92c0-1.51-1.22-2.73-2.73-2.73Z" />
          <path d="M1.76,15.69c-.15,0-.3,.03-.44,.09-.16,.06-.31,.15-.43,.28-.48,.48-.48,1.27,0,1.75,.24,.24,.56,.36,.88,.36s.63-.12,.88-.36c.14-.14,.22-.3,.28-.47,.15-.44,.07-.94-.28-1.29-.24-.24-.56-.36-.88-.36Z" />
          <path d="M4.97,13.72c-.34-.34-.72-.6-1.12-.81-.51-.27-1.06-.44-1.62-.5-.03,0-.06,0-.09,0-.47,0-.86,.38-.84,.86h0c.02,.4,.32,.75,.72,.79,.65,.05,1.29,.33,1.79,.83s.77,1.14,.83,1.79c.03,.4,.38,.7,.78,.72h.04c.5,0,.87-.44,.82-.93-.06-.58-.24-1.14-.53-1.67-.2-.38-.45-.75-.78-1.07Z" />
          <path d="M1.16,9.96c.02,.43,.36,.77,.8,.78,.65,.02,1.29,.15,1.9,.37,.83,.3,1.62,.77,2.29,1.44,.65,.65,1.12,1.42,1.42,2.24,.23,.63,.36,1.29,.38,1.95,.01,.4,.32,.72,.71,.78,.03,0,.05,.02,.08,.02l.84,.04s0-.04,0-.05c.07-.92-.03-1.84-.28-2.73-.35-1.25-1.01-2.43-1.98-3.4s-2.19-1.66-3.45-2c-.68-.19-1.39-.29-2.09-.29-.21,0-.43,0-.64,.03h0v.1l.04,.74Z" />
        </g>
      </g>
    </svg>
  );
};
