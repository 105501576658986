import React from 'react';

export const CloseIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    aria-hidden="true"
    width="25px"
    height="25px"
    viewBox="0 0 162 125"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M15.5,0.5 L146.5,124.5" stroke="currentColor" strokeWidth="21" />
      <path d="M145.456602,5.68434189e-14 L15,124.571342" stroke="currentColor" strokeWidth="21" />
    </g>
  </svg>
);
