import React from 'react';

export const LockIcon = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M0 7h12.063v8.588H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M10 3.89C10 1.744 8.205 0 6 0S2 1.745 2 3.89V5h1.715V3.89C3.715 2.663 4.74 1.666 6 1.666c1.26 0 2.285.997 2.285 2.222V5H10V3.89z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        d="M6.71 11.199v2.854a1.5 1.5 0 0 1-1.5-1.5v-1.354a1.493 1.493 0 0 1-.75-1.292 1.5 1.5 0 0 1 3 0c0 .555-.303 1.032-.75 1.292M9.064 7H0v5.588a3 3 0 0 0 3 3h9.063V10a3 3 0 0 0-3-3"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
