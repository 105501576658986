import React from 'react';

export const ShoppingBasketIcon = () => (
  <svg role="none" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <path
        fill="currentColor"
        d="M17.2 17.8a.8.8 0 01-1.5 0v-6a.8.8 0 011.5 0v6zm-4.5 0a.8.8 0 01-1.5 0v-6a.8.8 0 011.5 0v6zm-4.5 0a.8.8 0 01-1.5 0v-6a.8.8 0 011.5 0v6zM24 8l-3.6 10.4c-.6 1.9-2.4 3.1-4.4 3.1H8c-2 0-3.8-1.2-4.4-3L0 8h24zM14 .5l6 6h-4.3l-2.3-2.3a2.2 2.2 0 010-3.2l.5-.5zM10 .5l.6.5a2.2 2.2 0 010 3.2L8.4 6.5H4l6-6z"
      />
    </g>
  </svg>
);
